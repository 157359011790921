
import BusinessContact from "@/components/company/BusinessContact";
import CompanyInfo from "@/components/company/CompanyInfo";
import OperationContact from "@/components/company/OperationContact";
import DefaultSetting from "@/components/default/DefaultSetting";
import DomainWhiteList from "@/components/default/DomainWhiteList";
export default {
  components: {
    BusinessContact,
    CompanyInfo,
    OperationContact,
    DefaultSetting,
    DomainWhiteList,
  },
  data() {
    return {
      tabs: "companyInfo",
    }
  },
}
